import React, {useEffect, useRef} from "react"
import * as styles from './Menu.module.scss'

const Menu = () => {
  const menuRef = useRef();
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
  })
  
  const handleScroll = () => {
    const menuEl = menuRef.current;
    let sticky = false;
    const treshold = 320;
    if(!sticky && menuEl.offsetTop - window.pageYOffset < 5) {
      sticky = true;
      menuEl.style.position = "fixed";
    }
    if(sticky && window.pageYOffset < treshold) {
      menuEl.style.position = "relative";
      sticky = false;
    }
  }

  return (
    <div className={styles.menuArea}>
      <div className={styles.menuContainer} ref={menuRef}>
        <menu className={styles.topMenu}>
          <a id="menu-" href='/' title='HOME' class={styles.home}>⌂</a>
          <a id='menu-rates' href='/rates'>Rates</a>
          <a id='menu-visit' href='/visit'>Plan a Visit</a>
          <a id='menu-events' href='/events'>Events</a>
          <a id='menu-groups' href='/parties'>Parties</a>
          <a id='menu-classes' href='/classes'>Classes</a>
          <a id='menu-youth' href='/youth'>Youth Programs</a>
          <a id='menu-summer-camps' href='/summer-camps'>Summer Camps</a>
          <a id='menu-half-moon-full-send' href='/half-moon-full-send'>Full Send</a>
        </menu>
      </div>
    </div>
  )
}

export default Menu;